'use strict';

// const waypoints = require('waypoints');

module.exports = {

  init: function(){

    var inner = window.innerHeight * 0.382;
    // $('.container').css('margin-top', inner);

    // add line to DOM for visual debugging
    var triggerLine = $('<div class="trigger-line" style="top: '+inner+'px"></div>');
    $('.viewport').append(triggerLine);

    // switch active sections
    var opacity = 0.2;
    var waypoints = $('section').waypoint({
      offset: inner,
      handler: function(direction) {
        var $this = $(this.element), $prev = $this.prev(), $next = $this.next();
        if(direction === "up"){
          $next = $this;
          $this = $prev;
          $prev = $this.prev();
        }

        if($prev.length){
          $prev.css('opacity', opacity);
        }
        if($next.length){
          $next.css('opacity', opacity);
        }
        $this.css('opacity', 1);

        $('section').removeClass('active');
        $this.addClass('active');

        $('.link-list li').removeClass('active');
        $('.link-list li.'+$this.data('section')).addClass('active')
      }
    });

    // see the href of a text link by hovering over it
    $('article a').each((i,el)=>{
      console.log(i,el);
      var $this = $(el);
      $this.attr('title', $this.attr('href'));
    });

    $('article a').each((i,el)=>{
      console.log(i,el);
      var $this = $(el);
      $this.attr('title', $this.attr('href'));
    });

    // open and close the hamburgur menu
    $('#mobile-nav .hamburger').click(function(){
      $(this).toggleClass('is-active');
      $(this).parent().toggleClass('active');
    });

    $('.link-list li').click(function(){
      var target = $(this).data('section');
      var offset = $('section.'+target+':first').offset().top;
      $('body,html').animate({
        scrollTop: offset - inner + 200
      }, 800);
    });

    // listen for keyboard users
    $('body').on('keydown', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode == 9) {
        $('body').addClass('accessible');
      }
    });

    $('.logo').addClass('fadeInDownBig');

    var animationName = 'pulse';
    var animationFinished = true;
    $('.logo').on('click', function(){
      $('.logo').removeClass('fadeInDownBig');

      $('html,body').animate({
        scrollTop: $(document).innerHeight()
      }, 1000);

      if(animationFinished){
        $(this).addClass(animationName);
        animationFinished = false;
      }
      setTimeout(()=>{
        animationFinished = true;
        $('.logo').removeClass(animationName)
      }, 1000);
    });

    $('.logo').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', ()=>{
      animationFinished = true;
      $('.logo').removeClass(animationName);
    });

    // var stutter = 500;
    // $('#homepage-header img').each(function(i,el){
    //   let index = i, $el = $(el);
    //   setTimeout(function(){
    //     $el.css('opacity', 1);
    //     $el.addClass('fadeIn');
    //   }, index * stutter);
    // });

    var flickity = new Flickity($('.gallery')[0],{
      cellSelector: '.slide',
      wrapAround: true,
      autoPlay: true,
    });

    $('.jump-down').click(function(){
      $('html,body').animate({
        scrollTop: $('section.active').next().offset().top
      });
    });
  }
};
