'use strict';

module.exports = {

  // window.variables.globalVariable
  globalVariable: 100,

  // window.variables.globalFunction();
  globalFunction: function(){ }
};
