'use strict';


// Third Party Libraries
require('imagesloaded');
window._ = require('lodash/function');
window.Flickity = require('flickity');
window.variables = require('./modules/variables');

// Import Javascript modules here:
var Homepage = require('./modules/homepage');

$(document).ready(()=>{
  $('html').removeClass('hidden');
  Homepage.init();
});
